import React, { useEffect, useState } from "react"
import SEO from "layout/SEO"
import Layout from "../components/Layout/Layout"
import HomeBanner from "components/StaticPages/Home/HomeBanner"
import HomeCard from "components/StaticPages/Home/HomeCard"
import HomeProcessDesktop from "components/StaticPages/Home/HomeProcessDesktop"
import HomeProcessMobile from "components/StaticPages/Home/HomeProcessMobile"
import Container from "layout/Container"
import ContactUs from "components/StaticPages/HelpCenter/ContactUs"
import Cookies from "js-cookie"
export default () => {
  const [loggedInUser, setLoggedInUser] = useState({})

  useEffect(() => {
    const userCookie = Cookies.get("loggedInUser")
    if (userCookie) {
      try {
        const user = JSON.parse(userCookie)
        setLoggedInUser(user)
      } catch (error) {
        console.error("Error parsing user cookie:", error)
      }
    }
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <HomeBanner loggedInUser={loggedInUser} />
      {Object.keys(loggedInUser).length > 0 ? (
        <>
          <HomeProcessDesktop />
          <HomeProcessMobile />
          <HomeCard />
        </>
      ) : null}

      {/* <Container isCentered>
        <ContactUs />
      </Container> */}
    </Layout>
  )
}
