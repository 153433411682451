import { signInWithPopup } from "firebase/auth"
import {
  getAuthApp,
  getFacebookAuthProvider,
} from "../../../services/firebase/firebase"
import Cookies from "js-cookie"

const auth = getAuthApp()
const provider = getFacebookAuthProvider()
export const handleFacebookSignIn = async ({ setLoading, setErrorMessage }) => {
  try {
    setLoading(true)

    const timeout = new Promise((_, reject) =>
      setTimeout(
        () => reject(new Error("Log-in timed out. Please try again.")),
        200000
      )
    )

    const fbAuth = await Promise.race([
      signInWithPopup(auth, provider),
      timeout,
    ])

    if (!fbAuth || !fbAuth.user) {
      throw new Error("Authentication failed. No user data returned.")
    }

    const userData = {
      uid: fbAuth.user.uid,
      displayName: fbAuth.user.displayName,
      email: fbAuth.user.email,
      photoURL: fbAuth.user.photoURL,
    }

    Cookies.set("loggedInUser", JSON.stringify(userData), {
      expires: 1,
      path: "/",
      sameSite: "Lax",
      secure: false,
    })

    setLoading(false)
    return userData
  } catch (err) {
    setLoading(false)
    setErrorMessage(err.message || "Log-in failed. Please try again.")
    console.error("Facebook sign-in error:", err)
    return null
  }
}
