import {
  signInWithPopup,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth"
import {
  getAuthApp,
  getGoogleAuthProvider,
} from "../../../services/firebase/firebase"
import Cookies from "js-cookie"

export const handleGoogleSignIn = async ({ setLoading, setErrorMessage }) => {
  setLoading(true)

  const auth = getAuthApp()
  const provider = getGoogleAuthProvider()
  await setPersistence(auth, browserSessionPersistence)

  const timeout = new Promise((_, reject) =>
    setTimeout(
      () => reject(new Error("Log-in timed out. Please try again.")),
      200000
    )
  )

  await Promise.race([
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result?.user

        Cookies.set("loggedInUser", JSON.stringify(user), {
          expires: 1,
          path: "/",
          sameSite: "Lax",
          secure: false,
        })

        setLoading(false)
        return user
      })
      .catch((error) => {
        console.log(error.message)
        setLoading(false)
        setErrorMessage(error.message || "Log-in failed. Please try again.")
        console.error("Google sign-in error:", error.message)
        return null
      }),
    timeout,
  ])
}
