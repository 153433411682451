import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Cookies from "js-cookie"

import Hero from "layout/Hero"
import Container from "layout/Container"
import Button from "elements/Button"

import styles from "../utils/staticPages.module.scss"
import { animate } from "../../../services/animations"
import { handleFacebookSignIn } from "../services/handleFacebookSignIn"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { handleGoogleSignIn } from "../services/handleGoogleSignIn"

const HomeBanner = ({ loggedInUser }) => {
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      target: ".fade-from-left",
      duration: 1,
    })
  }, [])

  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "index__hero--heart.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      facebook: file(relativePath: { eq: "icons/facebook.svg" }) {
        publicURL
      }
      google: file(relativePath: { eq: "icons/google.svg" }) {
        publicURL
      }
      apple: file(relativePath: { eq: "icons/apple.svg" }) {
        publicURL
      }
    }
  `)

  const heart = data.heart.childImageSharp.fluid.src
  const facebookIcon = data.facebook.publicURL
  const googleIcon = data.google.publicURL
  const appleIcon = data.apple.publicURL

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container
        desktop={10}
        fullhd={10}
        customClassName="ml-32 fade-from-left"
      >
        <div className={`${styles["heroContainer"]}`}>
          <h1 className={classNames(" has-text-primary", styles["bannerText"])}>
            Delivering healthcare <br />
            everywhere
          </h1>
          {Object.keys(loggedInUser).length > 0 ? (
            <div className={classNames(styles["buttons"])}>
              <Button
                color="primary"
                size="medium"
                onClick={() => navigate("/epharmacy/order")}
              >
                Order Medicines
              </Button>
            </div>
          ) : (
            <div
              className={`${styles["buttonsContainer"]}  is-flex is-flex-direction-column p-1 is-align-items-center rounded-md`}
            >
              <h4 className={classNames("has-text-black mt-1")}>
                Sign Up or Log In
              </h4>
              {errorMessage && (
                <p
                  className={classNames("has-text-danger text-xs text-center")}
                >
                  {errorMessage}
                </p>
              )}
              {!loading ? (
                <>
                  <Button
                    size="large"
                    className={`${styles["buttonsSocial__facebook"]} is-flex is-align-items-center `}
                    onClick={async () => {
                      setErrorMessage("")
                      const user = await handleFacebookSignIn({
                        setLoading,
                        setErrorMessage,
                      })
                      window.location.reload()
                    }}
                  >
                    <img
                      src={facebookIcon}
                      alt="Facebook"
                      style={{ width: "20px", marginRight: "8px" }}
                    />
                    Continue with Facebook
                  </Button>

                  <Button
                    size="large"
                    className={`${styles["buttonsSocial__google"]} is-flex is-align-items-center`}
                    onClick={async () => {
                      const user = await handleGoogleSignIn({
                        setLoading,
                        setErrorMessage,
                      })
                      window.location.reload()
                    }}
                  >
                    <img
                      src={googleIcon}
                      alt="Google"
                      style={{ width: "20px", marginRight: "8px" }}
                    />
                    Continue with Google
                  </Button>

                  <Button
                    size="large"
                    className={`${styles["buttonsSocial__apple"]} is-flex is-align-items-center`}
                    onClick={() => navigate("/epharmacy/order")}
                  >
                    <img
                      src={appleIcon}
                      alt="Apple"
                      style={{ width: "20px", marginRight: "8px" }}
                    />
                    Continue with Apple
                  </Button>
                </>
              ) : (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="mb-1"
                />
              )}
              <p className={classNames("has-text-black text-xs text-center")}>
                By signing up, you agree to our{" "}
                <span className="has-text-primary is-underlined">
                  Terms and Conditions
                </span>
                ,{" "}
                <span className="has-text-primary is-underlined">
                  Privacy Policy
                </span>
                , and{" "}
                <span className="has-text-primary is-underlined">
                  Cookie Policy
                </span>
                .
              </p>
            </div>
          )}
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBanner
